import { Environment } from 'src/app/shared/models/environment.interface';
import { baseEnvironmentProd } from 'src/environments/baseEnvironment.prod';

export const environment:Environment = {
  ...baseEnvironmentProd,
	requestDatasheetUrl: 'https://merlo-sav.datalean-nodejs.catalean.com/merlo-sav/',
  organizationPrefix: 'MER',
	organizationUUID: '88811bb2-020a-4b4d-b400-13efabff1ace',
  activeLanguage: 'it-IT'
};
